<template>
  <div>
    <vx-card class="" no-shadow card-border>
      <div class="vx-row mb-6">
        <div class="vx-col w-full align-center my-2">
          <span>Car Rental Rule Criteria:</span>
        </div>
        <!--#region  suppliers field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Suppliers:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="carLocalForm.carRuleCriteria.suppliers"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.carSupplier"
            />
          </div>
        </div>
        <!--#endregion  suppliers field-->

        <!--#region  Rental Operator field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Rental Operators:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="carLocalForm.carRuleCriteria.rentalOperators"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.carRentalOperators"
            />
          </div>
        </div>
        <!--#endregion  Rental Operator field-->
      </div>
      <div class="vx-row mb-6">
        <!--#region  Transmission field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Transmissions:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="carLocalForm.carRuleCriteria.transmissions"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="carLocalHelper.enums.transmissions"
            />
          </div>
        </div>
        <!--#endregion  Transmission field-->

        <!--#region Rental Duration  -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Rental Durations:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="carLocalForm.carRuleCriteria.rentalDurations"
              :options="carLocalHelper.numberArray(1, 365)"
              :disabled="readOnly"
            />
            <span class="text-danger text-sm">{{
              errors.first("rentalDurations")
            }}</span>
          </div>
        </div>
        <!--#endregion Rental Duration ends -->

        <!--#region  Class Codes field-->
        <!-- <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>ClassCodes:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="carLocalForm.carRuleCriteria.classCodes"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="carLocalHelper.enums.classCodes"
            />
          </div>
        </div> -->
        <!--#endregion  Fare Types field-->
      </div>

      

      <div class="vx-row mb-6">
        <!--#region Location Match Type  field  -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Location Match Types:</span>
          </div>
          <div class="mt-4">
            <v-select
              v-model="carLocalForm.carRuleCriteria.locationMatchType"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="carLocalHelper.enums.locationMatchTypes"
            />
          </div>
        </div>
        <!--#endregion Location Match Type ends -->

        <!--#region Country field -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Country:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="carLocalForm.carRuleCriteria.countries"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.countryCode"
            />
            <span class="text-danger text-sm">{{
              errors.first("countries")
            }}</span>
          </div>
        </div>
        <!--#endregion Country field ends -->
      </div>

      <!--#region Class code  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Class Codes:</span>
        </div>
        <template
          v-for="(carlocation, carlocationIndex) in carLocalForm.carRuleCriteria
            .classCodes"
        >
         
          <!-- Value -->
          <div class="vx-col w-full md:w-1/2" :key="carlocationIndex + '1'">
            <div class="mt-4">
              <label class="text-sm"></label>
              <vs-input
                class="w-full"
                :name="'classCode_' + carlocationIndex"
                v-model="
                  carLocalForm.carRuleCriteria.classCodes[carlocationIndex]
                "
                v-validate="{ rules: { required: true, max: 200 } }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('classCode_' + carlocationIndex)"
                >{{
                  errors
                    .first("classCode_" + carlocationIndex)
                    .replace("classCode_" + carlocationIndex, "class code")
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/2" :key="carlocationIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeCarDynamicFields(carlocationIndex, 'classCode')"
                size="small"
                class="mt-3"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addCarDynamicFields('classCode')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Class Codes fields  ends -->

      <!--#region SIPP code  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>SIPP Codes:</span>
        </div>
        <template
          v-for="(carlocation, carlocationIndex) in carLocalForm.carRuleCriteria
            .sippCodes"
        >
         
          <!-- Value -->
          <div class="vx-col w-full md:w-1/2" :key="carlocationIndex + '1'">
            <div class="mt-4">
              <label class="text-sm"></label>
              <vs-input
                class="w-full"
                :name="'sippCode_' + carlocationIndex"
                v-model="
                  carLocalForm.carRuleCriteria.sippCodes[carlocationIndex]
                "
                v-validate="{ rules: { required: true, max: 200 } }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('sippCode_' + carlocationIndex)"
                >{{
                  errors
                    .first("sippCode_" + carlocationIndex)
                    .replace("sippCode_" + carlocationIndex, "sipp code")
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/2" :key="carlocationIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeCarDynamicFields(carlocationIndex, 'ssipCode')"
                size="small"
                class="mt-3"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addCarDynamicFields('ssipCode')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion SIPP Codes fields  ends -->

      <!--#region Locations  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Locations:</span>
        </div>
        <template
          v-for="(carlocation, carlocationIndex) in carLocalForm.carRuleCriteria
            .locations"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="carlocationIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'carLocationMatch_' + carlocationIndex"
                v-model="
                  carLocalForm.carRuleCriteria.locations[carlocationIndex]
                    .matchType
                "
                :options="carLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                 v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('carLocationMatch_' + carlocationIndex)"
                >{{
                  errors
                    .first("carLocationMatch_" + carlocationIndex)
                    .replace("carLocationMatch_" + carlocationIndex, "Car location match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="carlocationIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'carLocationValue_' + carlocationIndex"
                v-model="
                  carLocalForm.carRuleCriteria.locations[carlocationIndex].value
                "
                v-validate="{ rules: { required: true, max: 25 } }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('carLocationValue_' + carlocationIndex)"
                >{{
                  errors
                    .first("carLocationValue_" + carlocationIndex)
                    .replace("carLocationValue_" + carlocationIndex, "value")
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="carlocationIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeCarDynamicFields(carlocationIndex, 'carlocation')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addCarDynamicFields('carlocation')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Location fields  ends -->

      <!--#region Package Name  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Package Names:</span>
        </div>
        <template
          v-for="(packages, packageIndex) in carLocalForm.carRuleCriteria
            .packageNames"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="packageIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'packageMatch_' + packageIndex"
                v-model="
                  carLocalForm.carRuleCriteria.packageNames[packageIndex]
                    .matchType
                "
                :options="carLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
               <span
                class="text-danger text-sm"
                v-if="errors.has('packageMatch_' + packageIndex)"
                >{{
                  errors
                    .first("packageMatch_" + packageIndex)
                    .replace("packageMatch_" + packageIndex, "Package match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="packageIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'packageValue_' + packageIndex"
                v-model="
                  carLocalForm.carRuleCriteria.packageNames[packageIndex].value
                "
                v-validate="{ rules: { required: true, max: 25 } }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('packageValue_' + packageIndex)"
                >{{
                  errors
                    .first("packageValue_" + packageIndex)
                    .replace("packageValue_" + packageIndex, "value")
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="packageIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeCarDynamicFields(packageIndex, 'packages')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addCarDynamicFields('packages')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Package Name fields  ends -->
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import { CustomValidator } from "../DepositRuleEditorValidator.js";
const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  name: "CarCriteria",
  components: {},
  inject: ["$validator"],
  props: {
    helper: {
      type: Object,
    },
    form: {
      type: Object,
    },
    priceRulesFormData: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      carLocalHelper: this.helper,
      carLocalForm: this.form,
    };
  },

  methods: {
    validateform() {
      return this.$validator.validateAll();
    },
    //#region  Adding dynamic route fields in the form
    addCarDynamicFields(fieldType) {
      if (fieldType === "carlocation") {
        this.carLocalForm.carRuleCriteria.locations.push(
          _.cloneDeep(this.carLocalHelper.stringMatch)
        );
      }
      if (fieldType === "packages") {
        this.carLocalForm.carRuleCriteria.packageNames.push(
          _.cloneDeep(this.carLocalHelper.stringMatch)
        );
      }
      if (fieldType === "classCode") {
        this.carLocalForm.carRuleCriteria.classCodes.push(
          ""
        );
      }
      if (fieldType === "ssipCode") {
        this.carLocalForm.carRuleCriteria.sippCodes.push(
          ""
        );
      }
      
    },
    //#endregion

    //#region  Removing dynamic route fields in the form
    removeCarDynamicFields(index, fieldType) {
      if (fieldType === "carlocation") {
        this.carLocalForm.carRuleCriteria.locations.splice(index, 1);
      }
      if (fieldType === "packages") {
        this.carLocalForm.carRuleCriteria.packageNames.splice(index, 1);
      }
       if (fieldType === "classCode") {
        this.carLocalForm.carRuleCriteria.classCodes.splice(index, 1);
      }
       if (fieldType === "ssipCode") {
        this.carLocalForm.carRuleCriteria.sippCodes.splice(index, 1);
      }
    },
    //#endregion
  },
  /**
   * When check box is unchecked, all its related form  should be reset   *
   */
  watch: {
    "form.productTypes": {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        if (
          oldValue &&
          oldValue.length > 0 &&
          oldValue.includes("CarRental") &&
          newValue &&
          !newValue.includes("CarRental")
        ) {
          this.carLocalForm.carRuleCriteria = _.cloneDeep(
            this.carLocalHelper.carCriteria
          );
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>